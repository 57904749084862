.container {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;


    @media screen and (min-width: 414px) {
        width: 414px;
    }

    @media screen and (min-width: 1024px) {
        width: 1024px;
        padding-left: 47px;
        padding-right: 47px;
    }

    @media screen and (min-width: 1280px) {
        width: 1280px;
        padding-left: 85px;
        padding-right: 85px;
    }
}