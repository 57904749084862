*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  line-height: 1.15;
}

body {
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

body {
  background-color: #000;
}

h3 {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 414px) {
  .container {
    width: 414px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 1024px;
    padding-left: 47px;
    padding-right: 47px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
    padding-left: 85px;
    padding-right: 85px;
  }
}

@media screen and (min-width: 300px) {
  .header {
    width: 100%;
    z-index: 1;
    background-color: #000;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    justify-content: center;
  }
}

.container__header {
  justify-content: space-between;
  align-items: center;
  gap: 1%;
  margin: 0;
  padding: 0;
  display: flex;
}

@media screen and (min-width: 300px) {
  .container__header {
    gap: 5%;
  }
}

.nav__list {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

@media screen and (min-width: 300px) {
  .nav__list {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .nav__list {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .nav__list {
    align-items: center;
    gap: 30px;
    display: flex;
  }
}

.nav__icon {
  display: block;
}

@media screen and (min-width: 1280px) {
  .nav__icon {
    display: none;
  }
}

.nav__title {
  color: #fff;
  text-decoration: none;
}

.nav__item {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1, 37;
  color: #fff;
  cursor: pointer;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.nav__item:hover, .nav__item:focus, .nav__item:active, .nav__title:hover, .nav__title:focus, .nav__title:active {
  color: #b99945;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  transition: color, underline .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 300px) {
  .nav__button {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .nav__button {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .nav__button {
    color: #000;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(#000, #000) padding-box padding-box, linear-gradient(to right, #fff, #b99945) border-box;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    padding: 16px 59px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    display: block;
  }
}

.nav__button:hover, .nav__button:focus, .nav__button:active {
  color: #b99945;
  background: linear-gradient(#000, #000) padding-box padding-box, linear-gradient(to left, #fff, #b99945) border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  transition: border, color .25s cubic-bezier(.4, 0, .2, 1);
}

.nav__icon-button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.container {
  position: relative;
}

.side-menu {
  width: 40vh;
  height: 100vh;
  opacity: .9;
  z-index: 1000;
  background-color: rgba(0, 0, 0, .7);
  border-right: 1px solid #f6f6f6;
  font-family: Source Sans Pro;
  transition: left .3s;
  position: fixed;
  top: 0;
  left: -120%;
}

.open-button {
  margin: 0;
  padding: 0;
}

.close-button {
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-family: Source Sans Pro;
  position: absolute;
  top: 133px;
  right: 20px;
}

.overlay-menu {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(2, 2, 2, .46);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.side-menu-content {
  color: #fff;
  padding: 20px;
  font-family: Source Sans Pro;
}

.list-menu {
  margin-top: 133px;
  padding: 0 20px;
  font-family: Source Sans Pro;
  list-style-type: none;
}

.list-menu-second {
  margin-top: 80px;
  padding: 0 20px;
  font-family: Source Sans Pro;
  list-style-type: none;
}

.menu-phone {
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  display: flex;
}

.menu-phone-description, .list-menu-title, .menu-item {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
}

.menu-item:hover, .menu-item:focus {
  color: #c59b3d;
}

.menu-social-container {
  margin: 0;
  padding: 0 16px;
}

.menu-social {
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  display: flex;
}

.menu-social-title {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.list-menu-item {
  margin-top: 10px;
}

.hero-section {
  width: 100%;
  text-align: center;
  background-image: url("hero_img.2267c4d1.jpg");
  background-size: cover;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 39px 0 5%;
}

.hero-section__content {
  margin-top: 100px;
  padding: 0 5%;
}

@media screen and (min-width: 1024px) {
  .hero-section__content {
    margin-top: 51px;
  }
}

.hero-section__title {
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 10px #c59b3d;
  color: #fff;
  background: linear-gradient(#000, #000) padding-box padding-box, linear-gradient(to top, #fff, #b99945) border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin: 0;
  padding: 18px 10px;
  font-family: Source Sans Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 1.25;
}

@media screen and (min-width: 1024px) {
  .hero-section__title {
    padding: 24px 18px;
    font-size: 84px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-section__title {
    padding: 32px 24px;
    font-size: 110px;
  }
}

.hero-section__icon-hero {
  width: 34px;
  height: 31px;
  margin-bottom: 5%;
}

@media screen and (min-width: 1024px) {
  .hero-section__icon-hero {
    width: 10vw;
    height: auto;
  }
}

@media screen and (min-width: 1280px) {
  .hero-section__icon-hero {
    width: 8vw;
  }
}

.hero-section__title-city {
  color: #b99945;
}

.hero-section__location {
  margin-top: 5%;
}

.hero-section__location-list {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: start;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .hero-section__location-list {
    justify-content: center;
    gap: 60px;
  }
}

.hero-section__location-place {
  justify-content: center;
  align-items: center;
  margin: 0 2%;
  display: flex;
}

.hero-section__text {
  color: #fff;
  text-align: left;
  padding-left: 20px;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.37;
}

@media screen and (min-width: 1024px) {
  .hero-section__text {
    font-size: 22px;
  }
}

.hero-section__icon {
  width: 27px;
  height: 40px;
  fill: #c59b3d;
}

@media screen and (min-width: 1024px) {
  .hero-section__icon {
    width: 37px;
    height: 50px;
  }
}

.hero-section__button {
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1, 37;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to left, #000, #c59b3d) padding-box padding-box, linear-gradient(to right, #b99945, #fff) border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin-top: 65px;
  margin-bottom: 70px;
  padding: 18px 69px;
  position: relative;
}

.hero-section__button:hover, .hero-section__button:focus, .hero-section__button:active {
  color: #b99945;
  background: linear-gradient(#000, #000) padding-box padding-box, linear-gradient(to right, #fff, #b99945) border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  transition: border, color .25s cubic-bezier(.4, 0, .2, 1);
}

.hero-section__button:after {
  content: "";
  height: 44px;
  width: 88px;
  background: url("after.667420b4.png") no-repeat;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: -17px;
  right: -17px;
}

.hero-section__button:before {
  content: "";
  height: 44px;
  width: 88px;
  background: url("before.2bbaa71d.png") no-repeat;
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: -17px;
  left: -17px;
}

.discount-section {
  background-image: url("discount-back.edd9bedf.jpg");
  background-size: cover;
  margin-top: 90px;
}

.discount-title {
  text-align: center;
  text-shadow: 0 0 6px #c59b3d;
  text-transform: uppercase;
  background: linear-gradient(#c59b3d 0%, #fff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-family: Source Sans Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .discount-title {
    padding: 24px 18px;
    font-size: 110px;
  }
}

.slider-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 35px auto;
  display: flex;
}

.slider {
  width: 100%;
  max-width: 1070px;
  height: 459px;
  position: relative;
  overflow: hidden;
}

.slider img {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 1px solid #fff;
  border-radius: 9px;
  transition: opacity .5s ease-in-out;
  display: none;
}

.slider img.active {
  opacity: 1;
}

.prev-button, .next-button {
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 123, 255, 0);
  border: none;
  padding: 10px;
}

.prev-button img, .next-button img {
  width: 20px;
  height: 20px;
}

.prev-button {
  margin-right: 10px;
}

.next-button {
  margin-left: 10px;
}

@media screen and (min-width: 1024px) {
  #myDiscount_m {
    display: none;
  }
}

@media screen and (min-width: 300px) {
  .tariffs-section {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    overflow: hidden;
  }
}

@media screen and (min-width: 1280px) {
  .tariffs-section {
    margin-top: 90px;
    display: block;
  }
}

@media screen and (min-width: 300px) {
  .tariffs-title {
    flex-wrap: wrap;
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .tariffs-title {
    text-align: center;
    text-shadow: 0 0 6px #c59b3d;
    text-transform: uppercase;
    background: linear-gradient(#c59b3d 0%, #fff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    font-family: Source Sans Pro;
    font-size: 110px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
  }
}

.tabs__nav {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .tabs__nav {
    flex-direction: row;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .tabs__nav {
    flex-wrap: nowrap;
    display: block;
  }
}

.tabs__item {
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background: #000;
  border: 1px solid #797979;
  border-radius: 10px;
  margin: 0 10px;
  padding: 20px 100px;
  text-decoration: none;
  transition: border .3s, box-shadow .3s;
  box-shadow: 0 36px 47px -18px rgba(121, 121, 121, .65);
}

.tabs__item:hover, .tabs__item:focus, .tabs__item.active {
  color: #c59b3d;
  border: 1px solid #c59b3d;
  transition: border .3s, box-shadow .3s;
  box-shadow: 0 36px 47px -18px rgba(197, 155, 61, .65);
}

@media screen and (min-width: 1024px) {
  .tabs__block-box {
    justify-content: center;
    align-items: center;
    gap: 10%;
    display: flex;
  }
}

.tabs__content-two {
  background-image: url("tariffs.d42eef45.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.tabs__content-ps {
  background-image: url("tariffs.d42eef45.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.tabs__content-xbox {
  background-image: url("xbox-bg.a22c717a.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.tabs__block-one, .tabs__block-two {
  width: 100%;
  flex: 1;
  margin: 0 10px;
}

.tariffs__title-box {
  width: 240px;
  background: #000;
  border: 2px solid #c59b3d;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 80px auto 50px;
  padding: 0;
  display: flex;
}

.tariffs__title {
  color: #fff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-sizing: border-box;
  margin: 0;
  padding: 10px 50px;
  font-family: Source Sans Pro;
  font-size: 32px;
  font-weight: 700;
}

.tariffs-button-container {
  justify-content: center;
  margin-top: 65px;
  display: flex;
}

.tariffs-section__button {
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to left, #000, #c59b3d) padding-box padding-box, linear-gradient(to right, #b99945, #fff) border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  padding: 14px 34px;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.37;
}

@media screen and (min-width: 1280px) {
  .tariffs-section__button {
    padding: 18px 69px;
  }
}

.tariffs-section__button:hover, .tariffs-section__button:focus, .tariffs-section__button:active {
  color: #b99945;
  background: linear-gradient(#000, #000) padding-box padding-box, linear-gradient(to right, #fff, #b99945) border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  transition: border, color .25s cubic-bezier(.4, 0, .2, 1);
}

.schedule-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  display: flex;
}

.schedule-container-ps {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.line {
  width: 100%;
  height: 2px;
  background: #c59b3d;
  margin-top: 15px;
}

.schedule-box {
  gap: 10px;
  margin-top: 17px;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .schedule-box {
    gap: 70px;
  }
}

@media screen and (min-width: 300px) {
  .time {
    text-align: center;
    color: #c59b3d;
    word-wrap: break-word;
    margin-top: 15px;
    font-family: Source Sans Pro;
    font-size: 32px;
    font-weight: 700;
    line-height: 34.39px;
    position: relative;
  }
}

@media screen and (min-width: 1024px) {
  .time {
    text-align: center;
    color: #c59b3d;
    word-wrap: break-word;
    margin-top: 15px;
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: 700;
    line-height: 34.39px;
    position: relative;
  }
}

@media screen and (min-width: 1280px) {
  .time {
    text-align: center;
    color: #c59b3d;
    word-wrap: break-word;
    flex: 1;
    margin-top: 15px;
    font-family: Source Sans Pro;
    font-size: 48px;
    font-weight: 700;
    line-height: 34.39px;
    position: relative;
  }
}

.price-one, .price-two, .price-tree, .price-four {
  text-align: center;
  white-space: nowrap;
  flex: 1;
  position: relative;
}

@media screen and (min-width: 300px) {
  .price-one:after {
    content: "VIP ZONE + 30 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 1024px) {
  .price-one:after {
    content: "VIP ZONE + 30 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 1280px) {
  .price-one:after {
    content: "VIP ZONE + 30 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 300px) {
  .price-two:after {
    content: "VIP ZONE + 20 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 1024px) {
  .price-two:after {
    content: "VIP ZONE + 20 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 1280px) {
  .price-two:after {
    content: "VIP ZONE + 20 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 300px) {
  .price-four:after {
    content: "VIP ZONE + 15 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 1024px) {
  .price-four:after {
    content: "VIP ZONE + 15 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

@media screen and (min-width: 1280px) {
  .price-four:after {
    content: "VIP ZONE + 15 грн.";
    color: #fff;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: -10px;
    left: 55%;
  }
}

.price-tree:after {
  content: "VIP ZONE + 20 грн.";
  color: #fff;
  white-space: nowrap;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -10px;
  left: 55%;
}

@media screen and (min-width: 1024px) {
  .price-tree:after {
    font-size: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .price-tree:after {
    font-size: 24px;
  }
}

.price-number {
  color: #fff;
  word-wrap: break-word;
  font-family: Source Sans Pro;
  font-size: 65px;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .price-number {
    font-size: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .price-number {
    font-size: 96px;
  }
}

.price-currency {
  color: #fff;
  word-wrap: break-word;
  font-family: Source Sans Pro;
  font-size: 40px;
  font-weight: 700;
}

.gradient-circle {
  width: 20px;
  height: 20px;
  background: linear-gradient(90deg, #c59b3d 0%, #fff 100%);
  border-radius: 50%;
  margin-top: 40px;
}

.vip-zone {
  color: #fff;
  word-wrap: break-word;
  flex: 1;
  margin-top: 10px;
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 700;
  position: relative;
}

.person {
  text-align: center;
  color: #c59b3d;
  word-wrap: break-word;
  font-family: Source Sans Pro;
  font-size: 48px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.person-text-ps {
  text-align: center;
  white-space: nowrap;
  flex: 1;
  position: relative;
}

.person-number {
  color: #c59b3d;
  word-break: break-word;
  font-family: Source Sans Pro;
  font-size: 65px;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .person-number {
    font-size: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .person-number {
    font-size: 96px;
  }
}

.person-name {
  color: #c59b3d;
  word-break: break-word;
  font-family: Source Sans Pro;
  font-size: 30px;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .person-name {
    font-size: 40px;
  }
}

.price-ps {
  text-align: center;
  white-space: nowrap;
  flex: 1;
  position: relative;
}

.price-ps:after {
  content: "VIP ZONE + 40 грн.";
  color: #fff;
  white-space: nowrap;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -10px;
  left: 55%;
}

@media screen and (min-width: 1024px) {
  .price-ps:after {
    font-size: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .price-ps:after {
    font-size: 24px;
  }
}

.price-xbox {
  text-align: center;
  white-space: nowrap;
  flex: 1;
  position: relative;
}

.tariffs-button-container-vr {
  justify-content: center;
  align-items: center;
  margin-top: 400px;
  display: flex;
}

.tabs__content-vr {
  background-image: url("vr-bg.04c02e64.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: auto;
}

.tabs__nav-btn {
  padding: 20px 35px;
}

@media screen and (min-width: 1024px) {
  .tabs__nav-btn {
    padding: 20px 100px;
  }
}

.tabs__nav-btn--active {
  background-color: none;
}

.discount-section {
  background-image: url("discount-back.edd9bedf.jpg");
  background-size: fill;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .discount-section {
    margin-top: 90px;
  }
}

.discount-title {
  text-align: center;
  text-shadow: 0 0 6px #c59b3d;
  text-transform: uppercase;
  background: linear-gradient(#c59b3d 0%, #fff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 70px 0 38px;
  font-family: Source Sans Pro;
  font-size: 85px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .discount-title {
    font-size: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .discount-title {
    font-size: 110px;
  }
}

.discount-section-container {
  margin-bottom: 30px;
}

@media screen and (min-width: 1280px) {
  .discount-section-container {
    margin-bottom: 170px;
  }
}

.discount-section-item {
  width: 100%;
  max-height: 150px;
  background: rgba(0, 0, 0, .56);
  border: 1px solid #c59b3d;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  box-shadow: 0 36px 47px -18px rgba(197, 155, 61, .65);
}

@media screen and (min-width: 1280px) {
  .discount-section-item {
    max-height: 450px;
  }
}

.filter-wrapper--date .date {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 40px;
  margin-right: 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .filter-wrapper--date .date {
    margin-bottom: 48px;
    margin-right: 16px;
  }
}

@media screen and (min-width: 1280px) {
  .filter-wrapper--date .date {
    margin-top: 28px;
    margin-bottom: 40px;
    margin-right: 16px;
  }
}

.slider-bg-section {
  background-image: url("slider-bg-one.57474415.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 80px;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .slider-bg-section {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.slider-bg-section:before, .slider-bg-section:after {
  content: "";
  height: 15px;
  background: linear-gradient(rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  right: 0;
}

.slider-bg-section:before {
  top: 0;
}

.slider-bg-section:after {
  bottom: 0;
}

.slider-bg-title {
  color: #fff;
  text-transform: uppercase;
  word-wrap: break-word;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 900;
}

@media screen and (min-width: 1280px) {
  .slider-bg-title {
    font-size: 100px;
  }
}

.slider-bg-title-second {
  color: #fff;
  text-transform: uppercase;
  word-wrap: break-word;
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 900;
}

@media screen and (min-width: 1280px) {
  .slider-bg-title-second {
    font-size: 100px;
  }
}

.tabs-two-section {
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  display: flex;
}

.tabs-two-title {
  text-align: center;
  text-shadow: 0 0 6px #c59b3d;
  text-transform: uppercase;
  background: linear-gradient(#c59b3d 0%, #fff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 40px;
  font-family: Source Sans Pro;
  font-size: 110px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.image-grid {
  grid-gap: 30px;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.image-item {
  width: 215px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 36px 48px -18px rgba(23, 78, 59, .65);
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.drone-section {
  margin-top: 90px;
}

.drone__title {
  text-align: center;
  text-shadow: 0 0 6px #c59b3d;
  text-transform: uppercase;
  background: linear-gradient(90deg, #c59b3d 0%, #fff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-family: Source Sans Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .drone__title {
    font-size: 70px;
  }
}

.drone__description {
  text-align: center;
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 10px;
  font-family: Source Sans Pro;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .drone__description {
    font-size: 48px;
  }
}

.drone__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
}

.drone__item {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 34px;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .drone__item {
    flex-direction: row;
    gap: 34px;
    margin-top: 45px;
  }
}

.drone__description-two {
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  flex-direction: column;
  margin-top: 30px;
  font-family: Source Sans Pro;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width: 1280px) {
  .drone__description-two {
    margin: 0;
    font-size: 36px;
  }
}

.slider-tree {
  margin-top: 90px;
}

.slider-tree-img {
  height: 324px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.location {
  margin-bottom: 94px;
}

.location-content {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  gap: 70px;
  margin-bottom: 15px;
  display: flex;
}

.location-block {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .location-block {
    flex-flow: row;
    align-items: center;
  }
}

.discount-title-l {
  text-align: center;
  text-shadow: 0 0 6px #c59b3d;
  text-transform: uppercase;
  background: linear-gradient(#c59b3d 0%, #fff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 40px 0 0;
  font-family: Source Sans Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .discount-title-l {
    padding: 24px 18px;
    font-size: 70px;
  }
}

@media screen and (min-width: 1280px) {
  .discount-title-l {
    font-size: 110px;
  }
}

.location-discription {
  flex-flow: row;
  align-items: baseline;
  gap: 14px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .location-discription {
    flex-wrap: wrap;
  }
}

.location-discription-text {
  color: #fff;
  word-wrap: break-word;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (min-width: 1024px) {
  .location-discription-text {
    font-size: 20px;
  }
}

.location-discription-item {
  justify-content: center;
  align-items: baseline;
  display: flex;
}

.location-icon {
  fill: #c59b3d;
}

.location-content-two {
  margin-top: 20px;
  display: block;
}

@media screen and (min-width: 1024px) {
  .location-content-two {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 70px;
    display: flex;
  }
}

.location-discription-m {
  flex-flow: row;
  align-items: baseline;
  gap: 38px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .location-discription-m {
    flex-flow: row;
    justify-content: center;
    gap: 10px;
  }
}

.location-discription-two {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 36px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .location-discription-two {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 70px;
    margin: 0;
    display: flex;
  }
}

.tariffs-button-container {
  margin: 0 0 20px;
}

.footer-title {
  color: rgba(255, 255, 255, .6);
  word-wrap: break-word;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
}

.footer-content {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

@media screen and (min-width: 414px) {
  .tabs-mobile-section {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .tabs-mobile-section {
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .tabs-mobile-section {
    display: none;
  }
}

/*# sourceMappingURL=index.45ad0485.css.map */
