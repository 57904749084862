.header {
  @media screen and (min-width: 300px) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    background-color: #000;
    padding-top: 14px;
    padding-bottom: 14px; 
  }

  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    gap: 20px;
    align-items: center; 
  }

  @media screen and (min-width: 1280px) {
    justify-content: center;
  }

}

.container__header {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1%;
  align-items: center;
  justify-content: space-between;
  

  @media screen and (min-width: 300px) {
    gap: 5%;
  }

}

.nav {
  &__list {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    @media screen and (min-width: 300px) {
      display: none;   
    }

    @media screen and (min-width: 1024px) {
      display: none; 
    }

    @media screen and (min-width: 1280px) {
      gap: 30px;
      display: flex;
      align-items: center;
    }
    
  }

  &__icon {
      display: block;

  @media screen and (min-width: 1280px) {
      display: none;
  }
  }

  &__title {
    color: #fff;
    text-decoration: none;
  }


&__item {
  display: inline-block;
  margin: 0;
  list-style: none;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1,37;
  color: #fff;
  cursor: pointer;
  }

&__item, &__title{
    &:hover,
    &:focus,
    &:active { 
  color: rgba(185, 153, 69, 1);
  text-decoration: underline;
  text-decoration-thickness: 3px;
  transition: color, underline 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

&__button {
  @media screen and (min-width: 300px) {
    display: none;   
  }

  @media screen and (min-width: 768px) {
    display: none;  
  }

@media screen and (min-width: 1280px) {
  display: block;
  color: #000;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 59px;
  color: #fff;
  background: linear-gradient(#000, #000) padding-box,
              linear-gradient(to right, rgba(255, 255, 255, 1), rgba(185, 153, 69, 1)) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
}

  }

    &__button {
        &:hover,
        &:focus,
        &:active {
        color: rgba(185, 153, 69, 1);
        background: linear-gradient(#000, #000) padding-box,
        linear-gradient(to left, rgba(255, 255, 255, 1), rgba(185, 153, 69, 1)) border-box;
        border: 2px solid transparent;
        border-radius: 10px;
        transition: border, color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

  }
    &__icon-button {
      cursor: pointer;
      background: none;
      padding: 0;
      margin: 0;
      border: none;
    }

}