.slider-bg-section {
    padding-top: 100px;
    padding-bottom: 100px;
    
    margin-top: 80px;
    position: relative;
    background-image: url("../images/slider-bg-one.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 1024px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    
        @media screen and (min-width: 1280px) {
           
        }
}

.slider-bg-section::before,
.slider-bg-section::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 15px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.slider-bg-section::before {
    top: 0;
}

.slider-bg-section::after {
    bottom: 0;
}

.slider-bg-title {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 24px;
    font-family: Source Sans Pro;
    font-weight: 900;
    text-transform: uppercase;
    word-wrap: break-word;
    @media screen and (min-width: 1280px) {
        font-size: 100px;
    }
}

.slider-bg-title-second {
    color: white;
    font-size: 24px;
    font-family: Source Sans Pro;
    font-weight: 900;
    text-transform: uppercase;
    word-wrap: break-word;
    @media screen and (min-width: 1280px) {
            font-size: 100px;
        }
}