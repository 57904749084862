.discount-section {
    margin-top: 90px;

    background-image: url("../images/discount-back.jpg");
    background-size: cover;
  }

.discount-title {
    margin: 0;
    text-align: center;
    text-shadow: 0px 0px 6px #C59B3D;
    font-family: Source Sans Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(180deg, #C59B3D 0%, #FFF 100%); 
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

@media screen and (min-width: 1024px) {
    padding: 24px 18px;
    font-size: 110px;
    }
}

.slider-container {
    width: 100%;
    margin: 35 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider {
    width: 100%;
    max-width: 1070px;
    height: 459px;
    overflow: hidden;
    position: relative;
}

.slider img {
    width: 100%;
    display: none;
    border-radius: 9px;
    border: solid 1px #fff;
    transition: opacity 0.5s ease-in-out;
    aspect-ratio: 16 / 9;
}

.slider img.active {
    opacity: 1;
}

.prev-button, .next-button {
    padding: 10px;
    background-color: #007bff00;
    color: #fff;
    border: none;
    cursor: pointer;
}

.prev-button img, .next-button img {
    width: 20px; /* Розмір ваших власних іконок */
    height: 20px;
}

.prev-button {
    margin-right: 10px; /* Відступ від правого краю слайдера */
}

.next-button {
    margin-left: 10px; /* Відступ від лівого краю слайдера */
}

#myDiscount_m {
    @media screen and (min-width: 1024px) {
        display: none;
        }
}



