.location {
    margin-bottom: 94px;
}

.location-content {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: nowrap;
    gap: 70px;
    margin-bottom: 15px;
}

.location-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
        }
}

.discount-title-l {
    margin: 0;
    margin-top: 40px;
        text-align: center;
        text-shadow: 0px 0px 6px #C59B3D;
        font-family: Source Sans Pro;
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        background: linear-gradient(180deg, #C59B3D 0%, #FFF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        

        @media screen and (min-width: 1024px) {
                padding: 24px 18px;
                font-size: 70px;
            }

            @media screen and (min-width: 1280px) {
                font-size: 110px;
            }
}

.location-discription {
    display: flex;
    flex-wrap: nowrap;
    gap: 14px;  
    align-items: baseline;
    flex-flow: nowrap;

    @media screen and (min-width: 1024px) {
        flex-wrap: wrap;
        }
}

.location-discription-text {
    color: white;
    font-size: 20px;
    font-family: Open Sans;
    font-weight: 600;
    word-wrap: break-word;

    @media screen and (min-width: 1024px) {
        font-size: 20px;
        }
}

.location-discription-item {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.location-icon {
    fill: rgba(197, 155, 61, 1);
}


.location-content-two {
    display: block;
    
    
    margin-top: 20px;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
            
            align-items: baseline;
                flex-wrap: nowrap;
                gap: 70px;
        }
}

.location-discription-m {
    display: flex;
    gap: 38px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    @media screen and (min-width: 1024px) {
flex-direction: row;
justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    }
    
}


.location-discription-two {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    gap: 50px;

    @media screen and (min-width: 1024px) {
        margin: 0;
            display: flex;
            align-items: start;
            justify-content: space-between;
    flex-wrap: nowrap;
            align-items: baseline;
            flex-wrap: nowrap;
            gap: 70px;
        }
}
.tariffs-button-container {
    margin: 0 0 20px 0;
}
