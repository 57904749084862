.footer-title {
    color: rgba(255, 255, 255, 0.60);
font-size: 20px;
font-family: Open Sans;
font-weight: 600;
word-wrap: break-word
}

.footer-content {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}