.drone-section {
    margin-top: 90px;
}

.drone__title {
    margin: 0;
    text-align: center;
    text-shadow: 0px 0px 6px #C59B3D;
    font-family: Source Sans Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(90deg, #C59B3D 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (min-width: 1024px) {
            font-size: 70px;
        }
}

.drone__description {
    margin-top: 10px;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    background-clip: text;

    @media screen and (min-width: 1024px) {
            font-size: 48px;
        }
}

.drone__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

.drone__item {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 34px;

    @media screen and (min-width: 1280px) {
            gap: 34px;
            margin-top: 45px;
            flex-direction: row;
        }
    
}

.drone__description-two {
    margin-top: 30px;
    font-family: Source Sans Pro;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    background-clip: text;
    flex-direction: column;

    @media screen and (min-width: 1280px) {
        margin: 0;
        font-size: 36px;
        }
}

