.slider-tree {
    margin-top: 90px;
}

.slider-tree-img {
    height: 324px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}