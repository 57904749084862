.hero-section {
    width: 100%;
    align-items: center;
    padding: 39px 0 5%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    background-image: url("../images/hero_img.jpg");
    background-size: cover;

    &__content {
        margin-top: 100px;
        padding: 0 5%;

        @media screen and (min-width: 1024px) { 
            margin-top: 51px;
        }
    }

    &__title {
        margin: 0;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 0px 10px #C59B3D;
        background: linear-gradient(#000, #000) padding-box,
            linear-gradient(to top, rgba(255, 255, 255, 1), rgba(185, 153, 69, 1)) border-box;
        border: 1px solid transparent;
        border-radius: 10px;
        color: #fff;
        padding: 18px 10px;
        font-size: 32px;
        line-height: 1.25;

        @media screen and (min-width: 1024px) {
            padding: 24px 18px;
            font-size: 84px;
        }

        @media screen and (min-width: 1280px) {
            padding: 32px 24px;
            font-size: 110px;
        }
    }

    &__icon-hero {
        
        width: 34px;
        height: 31px;
        margin-bottom: 5%;

        @media screen and (min-width: 1024px) {
            width: 10vw;
            height: auto;
        }

        @media screen and (min-width: 1280px) {
            width: 8vw;
        }
    }

    &__title-city {
        color: #B99945;
    }

    &__location {
        margin-top: 5%;
    }

    &__location-list {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        flex-wrap: wrap;

        @media screen and (min-width: 1024px) {
            justify-content: center;
            gap: 60px;
        }
    }

    &__location-place {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 2%;
    }

    &__text {
        font-size: 18px;
        padding-left: 20px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        line-height: 1.37;
        color: #fff;
        text-align: left;

        @media screen and (min-width: 1024px) {
            font-size: 22px;
        }
    }

    &__icon {
        width: 27px;
        height: 40px;
        fill: rgba(197, 155, 61, 1);

        @media screen and (min-width: 1024px) {
            width: 37px;
            height: 50px;
        }
    }

         &__button {
             position: relative;
             margin-top: 65px;
             padding: 18px 69px;
             font-family: 'Open Sans';
             font-style: normal;
             font-weight: 600;
             font-size: 24px;
             line-height: 1, 37;
             color: #fff;
             background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(197, 155, 61, 1)) padding-box,
                 linear-gradient(to right, rgba(185, 153, 69, 1), rgba(255, 255, 255, 1)) border-box;
             border: 2px solid transparent;
             border-radius: 10px;
             cursor: pointer;
             margin-bottom: 70px;
         }
    
         &__button {
    
             &:hover,
             &:focus,
             &:active {
                 color: rgba(185, 153, 69, 1);
                 background: linear-gradient(#000, #000) padding-box,
                     linear-gradient(to right, rgba(255, 255, 255, 1), rgba(185, 153, 69, 1)) border-box;
                 border: 2px solid transparent;
                 border-radius: 10px;
                 transition: border, color 250ms cubic-bezier(0.4, 0, 0.2, 1);
             }
         }
    
         &__button::after {
             content: "";
             background: url("../images/after.png") no-repeat;
             display: block;
             position: absolute;
             top: -17px;
             right: -17px;
             height: 44px;
             width: 88px;
             margin: 0 auto;
    
         }
    
         &__button::before {
             content: "";
             background: url("../images/before.png") no-repeat;
             display: block;
             position: absolute;
             bottom: -17px;
             left: -17px;
             height: 44px;
             width: 88px;
             margin: 0 auto;
    
             }
}