.tabs-two-section {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs-two-title {
    margin: 0;
text-align: center;
text-shadow: 0px 0px 6px #C59B3D;
font-family: Source Sans Pro;
font-size: 110px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-transform: uppercase;
background: linear-gradient(180deg, #C59B3D 0%, #FFF 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-bottom: 40px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px; 
  }
  
  .image-item {
    width: 215px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 36px 48px -18px rgba(23, 78, 59, 0.65);
    overflow: hidden; 
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }